import React from 'react';

const Logo = ({ classes, white }) => { 
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 1251.00 480.00"
      className={`${classes ? classes : 'text-gray-700'}`}
    >
      <g>
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 111.97 152.27
  Q 211.65 152.19 302.68 152.31
  C 307.17 152.32 312.17 152.46 316.39 153.29
  C 340.74 158.10 357.90 177.06 360.12 201.80
  Q 362.13 224.31 347.95 241.17
  Q 339.43 251.31 326.80 256.88
  Q 325.15 257.61 326.06 259.17
  L 358.13 313.74
  Q 358.43 314.25 357.84 314.25
  L 331.26 314.25
  A 0.93 0.93 0.0 0 1 330.46 313.79
  L 286.44 239.07
  A 1.16 1.15 73.7 0 1 287.39 237.33
  C 295.92 237.03 305.12 238.08 313.15 236.08
  C 325.50 233.01 334.93 222.57 336.23 209.76
  C 337.71 195.08 329.03 182.01 315.03 177.61
  Q 310.18 176.08 303.11 176.17
  C 290.22 176.32 277.35 176.45 264.52 176.01
  C 259.05 175.82 253.62 176.24 248.21 176.24
  Q 163.70 176.25 79.48 176.25
  A 0.35 0.35 0.0 0 1 79.15 175.77
  C 104.70 109.37 169.08 66.49 240.07 68.78
  C 289.90 70.39 336.03 94.15 366.36 133.42
  C 413.13 193.97 412.63 278.33 365.08 338.44
  Q 334.77 376.76 286.43 393.36
  Q 285.82 393.57 285.50 393.01
  L 277.32 378.43
  Q 276.98 377.82 277.65 377.62
  Q 282.34 376.25 286.74 374.56
  C 337.65 355.01 373.88 310.62 381.91 256.95
  C 393.59 178.87 341.89 105.64 264.81 89.32
  Q 254.61 87.15 244.88 86.58
  Q 191.33 83.40 147.88 114.29
  Q 127.13 129.04 111.58 151.52
  Q 111.05 152.27 111.97 152.27
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 551.53 235.94
  C 537.14 229.38 525.41 215.38 521.48 199.41
  C 518.96 189.21 520.23 177.58 519.95 166.47
  Q 519.75 158.23 520.95 151.94
  Q 524.89 131.31 543.01 117.76
  C 553.70 109.77 567.75 106.80 581.19 107.51
  Q 608.29 108.93 624.10 130.14
  Q 634.81 144.51 634.71 163.34
  C 634.66 174.14 635.53 185.89 633.81 195.70
  C 629.81 218.40 611.73 236.72 588.34 240.32
  Q 567.99 243.44 551.53 235.94
  Z
  M 557.05 137.34
  C 542.95 147.75 542.93 166.18 543.60 182.03
  C 544.15 194.67 548.74 206.79 560.17 213.43
  Q 577.07 223.25 594.47 213.33
  Q 603.78 208.03 607.86 197.46
  C 611.75 187.38 611.26 176.59 610.93 166.04
  C 610.47 151.59 604.03 139.22 590.79 133.29
  C 579.34 128.16 567.17 129.86 557.05 137.34
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 883.79 108.99
  C 897.54 108.71 907.29 117.20 907.27 131.32
  Q 907.22 183.42 907.26 237.95
  Q 907.26 238.50 906.71 238.50
  L 883.50 238.50
  A 0.25 0.25 0.0 0 1 883.25 238.25
  L 883.25 109.54
  Q 883.25 109.00 883.79 108.99
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 677.47 191.66
  Q 677.53 203.63 677.49 215.13
  C 677.44 229.44 670.36 241.57 654.60 241.55
  A 0.60 0.60 0.0 0 1 654.00 240.95
  L 654.00 111.74
  A 0.73 0.73 0.0 0 1 654.73 111.01
  Q 682.98 110.97 712.59 111.02
  Q 716.48 111.02 720.22 111.48
  Q 741.36 114.07 752.73 130.90
  Q 760.56 142.50 758.50 156.23
  Q 756.99 166.27 750.67 174.35
  Q 740.55 187.29 723.25 190.22
  Q 719.16 190.91 711.50 190.92
  Q 695.81 190.93 678.06 191.07
  Q 677.47 191.07 677.47 191.66
  Z
  M 692.88 168.12
  Q 698.63 168.46 712.67 168.29
  Q 725.17 168.15 732.09 159.21
  C 738.42 151.03 735.74 140.63 726.50 136.30
  Q 720.57 133.52 713.44 133.51
  Q 694.88 133.49 678.06 133.50
  Q 677.48 133.50 677.49 134.07
  Q 677.53 145.43 677.48 158.21
  Q 677.47 160.11 678.63 161.65
  C 681.85 165.95 687.54 167.81 692.88 168.12
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 964.75 185.04
  L 964.75 215.72
  Q 964.75 216.20 965.23 216.20
  Q 990.80 216.39 1012.96 216.15
  Q 1021.54 216.05 1025.81 217.72
  C 1034.44 221.10 1038.01 229.04 1038.50 238.16
  A 0.56 0.56 0.0 0 1 1037.94 238.75
  L 941.50 238.75
  A 0.25 0.25 0.0 0 1 941.25 238.50
  L 941.25 111.50
  Q 941.25 111.00 941.75 111.00
  L 1037.95 111.00
  Q 1038.52 111.00 1038.51 111.58
  C 1038.06 126.64 1028.24 133.57 1014.35 133.53
  Q 989.24 133.47 965.28 133.51
  Q 964.75 133.51 964.75 134.03
  L 964.75 161.25
  Q 964.75 161.75 965.25 161.75
  L 1015.71 161.75
  Q 1016.22 161.75 1016.21 162.27
  Q 1015.82 178.67 1001.94 183.27
  Q 997.63 184.70 988.96 184.57
  Q 976.24 184.38 965.24 184.55
  Q 964.75 184.56 964.75 185.04
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 445.40 133.97
  C 436.77 134.08 427.41 134.92 419.37 130.64
  Q 409.22 125.24 408.82 111.89
  Q 408.80 111.25 409.44 111.25
  L 506.46 111.25
  Q 507.12 111.25 507.09 111.91
  Q 506.39 129.28 491.73 133.07
  C 485.29 134.73 477.28 133.87 470.28 134.01
  Q 469.80 134.02 469.80 134.50
  Q 469.63 172.76 469.84 213.55
  Q 469.88 222.40 468.45 226.42
  C 465.07 235.96 456.64 239.99 446.44 239.89
  Q 446.00 239.88 446.00 239.45
  L 446.00 134.56
  Q 446.00 133.96 445.40 133.97
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 802.49 133.95
  Q 795.95 134.10 789.47 133.94
  C 775.62 133.60 766.32 125.67 765.98 111.83
  A 0.57 0.57 0.0 0 1 766.55 111.25
  L 863.69 111.25
  Q 864.17 111.25 864.17 111.73
  C 864.18 124.04 856.90 133.21 844.05 133.85
  Q 838.01 134.15 827.51 134.05
  Q 827.00 134.05 827.00 134.55
  Q 827.01 178.62 826.99 217.19
  Q 826.99 236.22 808.64 239.62
  Q 806.46 240.02 803.83 239.86
  Q 803.25 239.82 803.25 239.24
  L 803.25 134.69
  Q 803.25 133.93 802.49 133.95
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 1138.87 189.70
  Q 1138.35 189.86 1138.66 190.30
  L 1171.34 237.77
  Q 1171.83 238.50 1170.96 238.50
  L 1142.81 238.50
  Q 1142.30 238.50 1142.02 238.08
  Q 1130.32 220.34 1116.94 199.56
  C 1110.80 190.02 1097.57 191.34 1087.85 191.52
  Q 1087.23 191.53 1087.24 192.15
  Q 1087.28 203.24 1087.23 215.32
  C 1087.16 229.46 1079.95 241.20 1064.25 240.23
  Q 1063.75 240.20 1063.75 239.70
  L 1063.75 111.79
  Q 1063.75 111.27 1064.28 111.27
  Q 1092.49 111.22 1124.87 111.26
  Q 1141.88 111.28 1154.65 121.77
  C 1162.04 127.83 1168.02 137.66 1168.99 147.30
  Q 1169.95 156.77 1166.49 165.22
  Q 1159.03 183.45 1138.87 189.70
  Z
  M 1145.57 151.07
  C 1146.18 138.17 1133.38 133.79 1122.82 133.77
  Q 1105.20 133.73 1087.84 133.75
  Q 1087.23 133.75 1087.23 134.36
  Q 1087.29 145.59 1087.22 158.68
  C 1087.18 165.79 1098.44 168.70 1103.78 168.71
  Q 1111.73 168.72 1119.89 168.83
  Q 1127.08 168.92 1131.79 167.32
  C 1138.38 165.07 1145.22 158.39 1145.57 151.07
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 238.51 383.40
  Q 201.69 316.92 166.85 254.03
  Q 157.15 236.53 147.09 219.20
  A 0.93 0.92 74.4 0 0 146.30 218.75
  L 105.59 218.75
  Q 105.09 218.75 104.84 218.32
  L 92.15 196.79
  A 1.52 1.51 74.7 0 1 93.45 194.50
  Q 198.75 194.39 300.16 194.49
  C 314.79 194.50 318.14 213.56 304.46 218.30
  C 301.77 219.23 299.13 218.76 296.90 218.76
  Q 275.60 218.74 258.00 218.82
  Q 256.83 218.82 255.81 219.27
  A 0.37 0.37 0.0 0 0 255.64 219.80
  L 311.48 314.68
  A 0.21 0.21 0.0 0 1 311.30 315.00
  L 283.50 315.00
  Q 282.89 315.00 282.59 314.48
  L 226.60 219.19
  Q 226.35 218.78 225.87 218.78
  L 177.24 218.78
  A 0.92 0.92 0.0 0 0 176.44 220.15
  Q 190.98 245.69 204.35 269.87
  Q 240.92 335.99 274.47 396.30
  Q 274.71 396.73 274.23 396.84
  C 225.94 408.33 176.03 398.53 136.09 369.18
  C 111.87 351.39 91.77 326.25 80.48 297.93
  Q 58.17 241.93 76.32 183.50
  A 0.73 0.72 8.8 0 1 77.01 183.00
  L 94.56 183.00
  Q 95.22 183.00 95.00 183.62
  C 58.99 282.90 133.15 385.57 238.16 384.00
  Q 238.83 383.99 238.51 383.40
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 614.89 289.07
  A 30.41 30.41 0.0 0 1 584.48 319.48
  A 30.41 30.41 0.0 0 1 554.07 289.07
  A 30.41 30.41 0.0 0 1 584.48 258.66
  A 30.41 30.41 0.0 0 1 614.89 289.07
  Z
  M 591.49 268.73
  Q 584.95 266.46 578.38 268.43
  C 565.49 272.32 561.88 286.53 566.09 298.29
  C 569.01 306.43 576.65 310.92 585.41 310.66
  C 595.38 310.36 602.55 303.35 604.13 293.57
  C 605.82 283.16 601.88 272.34 591.49 268.73
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 742.25 289.07
  A 30.41 30.41 0.0 0 1 711.84 319.48
  A 30.41 30.41 0.0 0 1 681.43 289.07
  A 30.41 30.41 0.0 0 1 711.84 258.66
  A 30.41 30.41 0.0 0 1 742.25 289.07
  Z
  M 717.92 309.78
  C 730.15 306.11 734.05 292.76 730.69 281.32
  C 727.16 269.32 714.36 264.64 703.26 269.32
  C 692.80 273.73 689.59 287.15 693.16 297.53
  C 696.79 308.09 707.54 312.89 717.92 309.78
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 1167.05 294.25
  L 1146.00 294.25
  Q 1145.75 294.25 1145.75 294.00
  L 1145.75 286.50
  Q 1145.75 286.25 1146.00 286.25
  L 1177.70 286.25
  Q 1178.30 286.25 1178.28 286.85
  C 1178.17 290.61 1178.64 294.31 1177.54 297.98
  C 1174.00 309.83 1164.12 318.09 1151.92 319.11
  C 1132.57 320.73 1117.74 306.10 1118.99 286.74
  C 1120.39 265.22 1141.97 253.39 1161.47 261.26
  Q 1172.27 265.62 1176.36 276.20
  Q 1176.58 276.75 1175.98 276.75
  L 1165.30 276.75
  Q 1164.80 276.75 1164.54 276.32
  Q 1159.93 268.79 1151.66 268.10
  Q 1137.05 266.89 1131.24 278.92
  Q 1127.41 286.89 1130.11 296.18
  Q 1133.17 306.70 1143.68 309.58
  C 1150.02 311.33 1157.89 309.97 1162.62 305.03
  Q 1166.53 300.95 1167.62 294.93
  Q 1167.74 294.25 1167.05 294.25
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 453.00 296.00
  L 453.00 318.50
  A 0.25 0.25 0.0 0 1 452.75 318.75
  L 443.25 318.75
  A 0.25 0.25 0.0 0 1 443.00 318.50
  L 443.00 259.79
  A 0.26 0.26 0.0 0 1 443.26 259.53
  Q 453.70 259.41 464.38 259.61
  C 476.07 259.82 485.67 266.82 484.27 279.60
  C 483.50 286.69 478.78 292.21 471.67 294.05
  Q 470.89 294.26 471.30 294.96
  L 485.22 318.29
  A 0.31 0.30 74.7 0 1 484.96 318.75
  L 474.24 318.75
  Q 473.62 318.75 473.31 318.22
  L 460.40 295.78
  Q 460.10 295.25 459.48 295.25
  L 453.75 295.25
  Q 453.00 295.25 453.00 296.00
  Z
  M 453.01 268.00
  L 452.97 286.92
  A 0.29 0.29 0.0 0 0 453.26 287.21
  L 464.65 287.23
  A 9.58 9.04 0.1 0 0 474.25 278.21
  L 474.25 276.79
  A 9.58 9.04 0.1 0 0 464.69 267.73
  L 453.30 267.71
  A 0.29 0.29 0.0 0 0 453.01 268.00
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 822.00 268.08
  L 822.00 284.43
  A 0.33 0.33 0.0 0 0 822.33 284.76
  L 841.17 284.75
  A 0.33 0.33 0.0 0 1 841.50 285.08
  L 841.50 292.67
  A 0.33 0.33 0.0 0 1 841.17 293.00
  L 822.33 293.00
  A 0.33 0.33 0.0 0 0 822.00 293.33
  L 822.00 318.42
  A 0.33 0.33 0.0 0 1 821.67 318.75
  L 812.58 318.75
  A 0.33 0.33 0.0 0 1 812.25 318.42
  L 812.25 259.83
  A 0.33 0.33 0.0 0 1 812.58 259.50
  L 846.67 259.50
  A 0.33 0.33 0.0 0 1 847.00 259.83
  L 847.00 267.42
  A 0.33 0.33 0.0 0 1 846.67 267.75
  L 822.33 267.75
  A 0.33 0.33 0.0 0 0 822.00 268.08
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 926.75 318.41
  A 0.34 0.34 0.0 0 1 926.41 318.75
  L 917.09 318.75
  A 0.34 0.34 0.0 0 1 916.75 318.41
  L 916.75 259.85
  A 0.34 0.34 0.0 0 1 917.09 259.51
  L 926.41 259.51
  A 0.34 0.34 0.0 0 1 926.75 259.85
  L 926.75 318.41
  Z"
        />
        <path
          fill="currentColor"
          d="
  M 1039.06 259.50
  L 1048.43 259.50
  A 0.32 0.32 0.0 0 1 1048.75 259.82
  L 1048.75 318.43
  A 0.32 0.32 0.0 0 1 1048.43 318.75
  L 1039.00 318.75
  A 0.32 0.32 0.0 0 1 1038.74 318.61
  L 1010.58 275.93
  A 0.32 0.32 0.0 0 0 1009.99 276.11
  L 1010.00 318.43
  A 0.32 0.32 0.0 0 1 1009.68 318.75
  L 1000.32 318.75
  A 0.32 0.32 0.0 0 1 1000.00 318.43
  L 1000.00 259.82
  A 0.32 0.32 0.0 0 1 1000.32 259.50
  L 1009.82 259.50
  A 0.32 0.32 0.0 0 1 1010.08 259.64
  L 1038.19 302.12
  A 0.32 0.32 0.0 0 0 1038.78 301.94
  L 1038.74 259.82
  A 0.32 0.32 0.0 0 1 1039.06 259.50
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 559.41 341.90
  Q 561.60 343.45 564.53 344.07
  C 567.90 344.78 571.15 346.51 571.27 350.41
  C 571.55 360.20 555.28 360.73 554.24 351.57
  Q 554.18 351.01 554.74 351.01
  L 558.00 351.00
  Q 558.60 351.00 558.80 351.57
  Q 560.09 355.36 564.45 354.29
  C 568.40 353.32 567.33 348.85 563.98 347.87
  C 560.79 346.93 554.80 345.80 554.40 341.47
  Q 553.84 335.49 559.28 333.81
  C 564.18 332.30 570.27 333.87 570.98 339.69
  Q 571.02 340.00 570.71 340.00
  L 566.75 339.99
  Q 566.13 339.99 565.95 339.40
  Q 565.07 336.57 561.82 336.96
  Q 557.73 337.45 558.99 341.33
  Q 559.11 341.68 559.41 341.90
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 649.07 345.68
  A 12.46 12.46 0.0 0 1 636.61 358.14
  A 12.46 12.46 0.0 0 1 624.15 345.68
  A 12.46 12.46 0.0 0 1 636.61 333.22
  A 12.46 12.46 0.0 0 1 649.07 345.68
  Z
  M 636.7686 354.4684
  A 8.78 8.18 88.9 0 0 644.7785 345.5330
  A 8.78 8.18 88.9 0 0 636.4314 336.9116
  A 8.78 8.18 88.9 0 0 628.4215 345.8470
  A 8.78 8.18 88.9 0 0 636.7686 354.4684
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 785.49 347.75
  L 778.51 347.76
  Q 778.01 347.76 778.01 347.26
  L 778.00 345.00
  A 0.50 0.50 0.0 0 1 778.50 344.50
  L 790.96 344.50
  Q 791.49 344.50 791.51 345.04
  C 792.10 360.74 770.12 362.77 767.33 347.98
  Q 766.61 344.16 768.12 340.56
  C 772.10 331.10 786.43 330.69 790.58 340.19
  Q 790.82 340.75 790.21 340.75
  L 786.32 340.75
  Q 785.74 340.75 785.43 340.26
  Q 783.83 337.73 781.04 337.31
  C 768.77 335.46 767.70 354.74 780.49 354.17
  A 5.88 5.88 0.0 0 0 784.99 351.76
  Q 785.82 350.62 786.49 349.70
  Q 787.91 347.75 785.49 347.75
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1037.33 354.19
  C 1041.40 352.87 1039.87 348.55 1036.46 347.75
  C 1032.97 346.94 1027.31 345.51 1027.11 341.00
  Q 1026.90 336.10 1030.88 334.26
  C 1035.60 332.08 1042.74 333.26 1043.73 339.44
  Q 1043.81 340.00 1043.25 340.00
  L 1039.70 340.00
  A 0.95 0.95 0.0 0 1 1038.82 339.41
  Q 1037.41 335.98 1033.34 337.24
  Q 1031.44 337.83 1031.45 339.64
  C 1031.48 345.25 1042.62 342.55 1043.93 349.52
  C 1045.85 359.76 1028.01 361.27 1027.01 351.54
  Q 1026.96 351.00 1027.49 351.00
  L 1030.80 351.00
  Q 1031.33 351.00 1031.51 351.50
  Q 1033.03 355.59 1037.33 354.19
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 446.31 354.50
  L 453.70 354.50
  A 0.31 0.31 0.0 0 1 454.01 354.82
  L 453.97 357.45
  A 0.31 0.31 0.0 0 1 453.66 357.75
  L 442.06 357.75
  A 0.31 0.31 0.0 0 1 441.75 357.44
  L 441.75 333.81
  A 0.31 0.31 0.0 0 1 442.06 333.50
  L 445.69 333.50
  A 0.31 0.31 0.0 0 1 446.00 333.81
  L 446.00 354.19
  A 0.31 0.31 0.0 0 0 446.31 354.50
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 466.75 347.57
  L 466.74 353.93
  A 0.32 0.32 0.0 0 0 467.06 354.25
  L 475.67 354.25
  A 0.32 0.32 0.0 0 1 475.99 354.57
  L 475.98 357.43
  A 0.32 0.32 0.0 0 1 475.66 357.75
  L 462.82 357.75
  A 0.32 0.32 0.0 0 1 462.50 357.43
  L 462.50 333.82
  A 0.32 0.32 0.0 0 1 462.82 333.50
  L 475.68 333.50
  A 0.32 0.32 0.0 0 1 476.00 333.82
  L 476.00 336.68
  A 0.32 0.32 0.0 0 1 475.68 337.00
  L 467.06 336.99
  A 0.32 0.32 0.0 0 0 466.74 337.31
  L 466.75 343.43
  A 0.32 0.32 0.0 0 0 467.07 343.75
  L 474.68 343.75
  A 0.32 0.32 0.0 0 1 475.00 344.07
  L 474.99 346.93
  A 0.32 0.32 0.0 0 1 474.67 347.25
  L 467.07 347.25
  A 0.32 0.32 0.0 0 0 466.75 347.57
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 490.93 336.99
  L 485.31 337.00
  A 0.32 0.32 0.0 0 1 484.99 336.68
  L 485.02 333.82
  A 0.32 0.32 0.0 0 1 485.34 333.50
  L 501.68 333.50
  A 0.32 0.32 0.0 0 1 502.00 333.82
  L 502.00 336.68
  A 0.32 0.32 0.0 0 1 501.68 337.00
  L 495.82 336.99
  A 0.32 0.32 0.0 0 0 495.50 337.31
  L 495.50 357.43
  A 0.32 0.32 0.0 0 1 495.18 357.75
  L 491.57 357.75
  A 0.32 0.32 0.0 0 1 491.25 357.43
  L 491.25 337.31
  A 0.32 0.32 0.0 0 0 490.93 336.99
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 530.40 352.05
  Q 532.63 355.26 537.10 353.99
  C 540.54 353.02 540.36 349.71 540.29 346.84
  Q 540.14 340.14 540.29 333.77
  Q 540.30 333.51 540.55 333.51
  L 544.04 333.50
  Q 544.52 333.50 544.51 333.98
  Q 544.42 340.24 544.56 348.03
  C 544.67 354.46 540.97 358.35 534.23 357.99
  C 527.37 357.63 525.28 353.04 525.43 346.78
  Q 525.61 339.85 525.47 334.00
  A 0.49 0.49 0.0 0 1 525.96 333.50
  L 529.50 333.51
  Q 529.75 333.51 529.75 333.75
  L 529.75 349.96
  A 3.69 3.65 -62.6 0 0 530.40 352.05
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 595.75 333.84
  A 0.26 0.26 0.0 0 1 596.01 333.58
  L 604.61 333.58
  A 11.84 11.53 0.0 0 1 616.45 345.11
  L 616.45 346.25
  A 11.84 11.53 0.0 0 1 604.61 357.78
  L 596.01 357.78
  A 0.26 0.26 0.0 0 1 595.75 357.52
  L 595.75 333.84
  Z
  M 599.71 337.34
  L 599.79 354.06
  A 0.27 0.27 0.0 0 0 600.07 354.33
  L 604.33 354.31
  A 7.84 7.83 89.7 0 0 612.11 346.43
  L 612.11 344.85
  A 7.84 7.83 89.7 0 0 604.23 337.05
  L 599.97 337.07
  A 0.27 0.27 0.0 0 0 599.71 337.34
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 676.75 357.39
  A 0.36 0.36 0.0 0 1 676.39 357.75
  L 672.87 357.75
  A 0.36 0.36 0.0 0 1 672.51 357.39
  L 672.51 333.87
  A 0.36 0.36 0.0 0 1 672.87 333.51
  L 676.39 333.51
  A 0.36 0.36 0.0 0 1 676.75 333.87
  L 676.75 357.39
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 691.93 336.99
  L 686.31 337.00
  A 0.32 0.32 0.0 0 1 685.98 336.67
  L 686.03 333.82
  A 0.32 0.32 0.0 0 1 686.35 333.50
  L 702.43 333.50
  A 0.32 0.32 0.0 0 1 702.75 333.82
  L 702.74 336.66
  A 0.32 0.32 0.0 0 1 702.42 336.98
  L 696.82 337.00
  A 0.32 0.32 0.0 0 0 696.50 337.32
  L 696.50 357.42
  A 0.32 0.32 0.0 0 1 696.18 357.74
  L 692.57 357.75
  A 0.32 0.32 0.0 0 1 692.25 357.43
  L 692.25 337.31
  A 0.32 0.32 0.0 0 0 691.93 336.99
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 730.75 348.73
  L 730.75 357.25
  Q 730.75 357.75 730.25 357.75
  L 726.75 357.75
  Q 726.50 357.75 726.50 357.50
  L 726.50 334.05
  Q 726.50 333.57 726.98 333.55
  C 731.74 333.30 741.85 332.46 743.22 338.74
  Q 744.64 345.28 738.73 347.71
  Q 738.16 347.94 738.47 348.47
  L 743.83 357.50
  A 0.17 0.16 75.0 0 1 743.69 357.75
  L 739.26 357.75
  Q 738.95 357.75 738.79 357.48
  L 733.82 348.78
  Q 733.54 348.29 732.97 348.27
  L 731.27 348.23
  Q 730.75 348.22 730.75 348.73
  Z
  M 730.74 337.31
  L 730.76 344.51
  A 0.28 0.28 0.0 0 0 731.04 344.79
  L 735.41 344.78
  A 3.87 3.70 -0.2 0 0 739.27 341.07
  L 739.27 340.71
  A 3.87 3.70 -0.2 0 0 735.39 337.02
  L 731.02 337.03
  A 0.28 0.28 0.0 0 0 730.74 337.31
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 757.99 357.44
  A 0.32 0.32 0.0 0 1 757.67 357.76
  L 754.07 357.76
  A 0.32 0.32 0.0 0 1 753.75 357.44
  L 753.75 333.82
  A 0.32 0.32 0.0 0 1 754.07 333.50
  L 757.67 333.50
  A 0.32 0.32 0.0 0 1 757.99 333.82
  L 757.99 357.44
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 804.75 347.55
  L 804.75 357.44
  A 0.30 0.30 0.0 0 1 804.45 357.74
  L 800.80 357.75
  A 0.30 0.30 0.0 0 1 800.50 357.45
  L 800.50 333.81
  A 0.30 0.30 0.0 0 1 800.80 333.51
  L 804.45 333.50
  A 0.30 0.30 0.0 0 1 804.75 333.80
  L 804.75 343.70
  A 0.30 0.30 0.0 0 0 805.05 344.00
  L 815.46 344.00
  A 0.30 0.30 0.0 0 0 815.76 343.70
  L 815.75 333.81
  A 0.30 0.30 0.0 0 1 816.05 333.51
  L 819.70 333.50
  A 0.30 0.30 0.0 0 1 820.00 333.80
  L 820.00 357.44
  A 0.30 0.30 0.0 0 1 819.70 357.74
  L 816.05 357.76
  A 0.30 0.30 0.0 0 1 815.75 357.46
  L 815.75 347.54
  A 0.30 0.30 0.0 0 0 815.45 347.24
  L 805.05 347.25
  A 0.30 0.30 0.0 0 0 804.75 347.55
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 839.75 337.31
  L 839.75 357.41
  A 0.32 0.32 0.0 0 1 839.43 357.73
  L 835.82 357.76
  A 0.32 0.32 0.0 0 1 835.50 357.44
  L 835.50 337.32
  A 0.32 0.32 0.0 0 0 835.18 337.00
  L 829.30 337.00
  A 0.32 0.32 0.0 0 1 828.98 336.68
  L 829.02 333.82
  A 0.32 0.32 0.0 0 1 829.34 333.50
  L 845.68 333.50
  A 0.32 0.32 0.0 0 1 846.00 333.82
  L 846.00 336.68
  A 0.32 0.32 0.0 0 1 845.68 337.00
  L 840.07 336.99
  A 0.32 0.32 0.0 0 0 839.75 337.31
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 879.00 337.36
  L 879.00 357.38
  A 0.37 0.37 0.0 0 1 878.63 357.75
  L 875.37 357.74
  A 0.37 0.37 0.0 0 1 875.00 357.37
  L 875.00 337.36
  A 0.37 0.37 0.0 0 0 874.63 336.99
  L 868.87 337.00
  A 0.37 0.37 0.0 0 1 868.50 336.63
  L 868.50 333.87
  A 0.37 0.37 0.0 0 1 868.87 333.50
  L 885.13 333.50
  A 0.37 0.37 0.0 0 1 885.50 333.87
  L 885.50 336.63
  A 0.37 0.37 0.0 0 1 885.13 337.00
  L 879.37 336.99
  A 0.37 0.37 0.0 0 0 879.00 337.36
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 898.75 347.59
  L 898.75 357.40
  A 0.34 0.34 0.0 0 1 898.41 357.74
  L 895.09 357.76
  A 0.34 0.34 0.0 0 1 894.75 357.42
  L 894.75 333.84
  A 0.34 0.34 0.0 0 1 895.09 333.50
  L 898.41 333.50
  A 0.34 0.34 0.0 0 1 898.75 333.84
  L 898.74 343.66
  A 0.34 0.34 0.0 0 0 899.08 344.00
  L 909.42 344.00
  A 0.34 0.34 0.0 0 0 909.76 343.66
  L 909.75 333.84
  A 0.34 0.34 0.0 0 1 910.09 333.50
  L 913.66 333.50
  A 0.34 0.34 0.0 0 1 914.00 333.84
  L 914.00 357.40
  A 0.34 0.34 0.0 0 1 913.66 357.74
  L 910.09 357.76
  A 0.34 0.34 0.0 0 1 909.75 357.42
  L 909.75 347.58
  A 0.34 0.34 0.0 0 0 909.41 347.24
  L 899.09 347.25
  A 0.34 0.34 0.0 0 0 898.75 347.59
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 928.75 347.57
  L 928.74 353.93
  A 0.32 0.32 0.0 0 0 929.06 354.25
  L 937.68 354.25
  A 0.32 0.32 0.0 0 1 938.00 354.57
  L 937.98 357.43
  A 0.32 0.32 0.0 0 1 937.66 357.75
  L 924.82 357.75
  A 0.32 0.32 0.0 0 1 924.50 357.43
  L 924.50 333.82
  A 0.32 0.32 0.0 0 1 924.82 333.50
  L 937.68 333.50
  A 0.32 0.32 0.0 0 1 938.00 333.82
  L 938.00 336.68
  A 0.32 0.32 0.0 0 1 937.68 337.00
  L 929.06 336.99
  A 0.32 0.32 0.0 0 0 928.74 337.31
  L 928.75 343.43
  A 0.32 0.32 0.0 0 0 929.07 343.75
  L 936.68 343.75
  A 0.32 0.32 0.0 0 1 937.00 344.07
  L 936.99 346.93
  A 0.32 0.32 0.0 0 1 936.67 347.25
  L 929.07 347.25
  A 0.32 0.32 0.0 0 0 928.75 347.57
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 966.75 347.56
  L 966.75 357.43
  A 0.31 0.31 0.0 0 1 966.44 357.74
  L 962.81 357.75
  A 0.31 0.31 0.0 0 1 962.50 357.44
  L 962.50 333.81
  A 0.31 0.31 0.0 0 1 962.81 333.50
  L 976.44 333.50
  A 0.31 0.31 0.0 0 1 976.75 333.81
  L 976.74 336.68
  A 0.31 0.31 0.0 0 1 976.43 336.99
  L 967.05 337.00
  A 0.31 0.31 0.0 0 0 966.74 337.31
  L 966.75 343.69
  A 0.31 0.31 0.0 0 0 967.06 344.00
  L 974.20 344.00
  A 0.31 0.31 0.0 0 1 974.51 344.31
  L 974.49 346.94
  A 0.31 0.31 0.0 0 1 974.18 347.25
  L 967.06 347.25
  A 0.31 0.31 0.0 0 0 966.75 347.56
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 989.99 357.44
  A 0.32 0.32 0.0 0 1 989.67 357.76
  L 986.07 357.76
  A 0.32 0.32 0.0 0 1 985.75 357.44
  L 985.75 333.82
  A 0.32 0.32 0.0 0 1 986.07 333.50
  L 989.67 333.50
  A 0.32 0.32 0.0 0 1 989.99 333.82
  L 989.99 357.44
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1004.50 348.75
  L 1004.50 357.50
  Q 1004.50 357.75 1004.25 357.75
  L 1000.50 357.75
  Q 1000.25 357.74 1000.25 357.50
  L 1000.25 334.18
  Q 1000.25 333.58 1000.85 333.55
  C 1005.99 333.27 1016.30 332.44 1017.17 339.51
  Q 1017.94 345.73 1012.59 347.62
  Q 1011.92 347.86 1012.28 348.46
  L 1017.68 357.58
  Q 1017.72 357.65 1017.64 357.66
  Q 1014.67 357.92 1012.73 357.60
  Q 1012.68 357.59 1012.65 357.54
  L 1007.55 348.68
  Q 1007.31 348.26 1006.82 348.26
  L 1005.00 348.25
  Q 1004.50 348.24 1004.50 348.75
  Z
  M 1004.49 337.32
  L 1004.51 344.50
  A 0.28 0.28 0.0 0 0 1004.79 344.78
  L 1009.31 344.77
  A 3.72 3.59 -0.1 0 0 1013.02 341.17
  L 1013.02 340.61
  A 3.72 3.59 -0.1 0 0 1009.29 337.03
  L 1004.77 337.04
  A 0.28 0.28 0.0 0 0 1004.49 337.32
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1063.24 337.32
  L 1063.25 357.44
  A 0.32 0.32 0.0 0 1 1062.93 357.76
  L 1059.32 357.74
  A 0.32 0.32 0.0 0 1 1059.00 357.42
  L 1059.00 337.31
  A 0.32 0.32 0.0 0 0 1058.68 336.99
  L 1053.07 337.00
  A 0.32 0.32 0.0 0 1 1052.75 336.68
  L 1052.75 333.82
  A 0.32 0.32 0.0 0 1 1053.07 333.50
  L 1069.43 333.50
  A 0.32 0.32 0.0 0 1 1069.75 333.82
  L 1069.75 336.67
  A 0.32 0.32 0.0 0 1 1069.43 336.99
  L 1063.56 337.00
  A 0.32 0.32 0.0 0 0 1063.24 337.32
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1102.75 337.31
  L 1102.75 357.42
  A 0.32 0.32 0.0 0 1 1102.43 357.74
  L 1098.82 357.75
  A 0.32 0.32 0.0 0 1 1098.50 357.43
  L 1098.50 337.32
  A 0.32 0.32 0.0 0 0 1098.18 337.00
  L 1092.30 337.00
  A 0.32 0.32 0.0 0 1 1091.98 336.68
  L 1092.03 333.82
  A 0.32 0.32 0.0 0 1 1092.35 333.50
  L 1108.68 333.50
  A 0.32 0.32 0.0 0 1 1109.00 333.82
  L 1109.00 336.68
  A 0.32 0.32 0.0 0 1 1108.68 337.00
  L 1103.07 336.99
  A 0.32 0.32 0.0 0 0 1102.75 337.31
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1122.49 357.39
  A 0.35 0.35 0.0 0 1 1122.14 357.74
  L 1118.60 357.74
  A 0.35 0.35 0.0 0 1 1118.25 357.39
  L 1118.25 333.85
  A 0.35 0.35 0.0 0 1 1118.60 333.50
  L 1122.14 333.50
  A 0.35 0.35 0.0 0 1 1122.49 333.85
  L 1122.49 357.39
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1153.81 342.71
  L 1147.59 356.69
  A 2.16 2.16 0.0 0 1 1143.65 356.69
  L 1137.69 343.31
  Q 1137.01 341.78 1137.01 343.46
  L 1137.00 357.25
  Q 1136.99 357.75 1136.50 357.75
  L 1133.51 357.76
  A 0.51 0.50 0.0 0 1 1133.00 357.26
  L 1133.00 334.12
  Q 1133.00 333.50 1133.63 333.50
  L 1136.77 333.50
  Q 1137.31 333.50 1137.53 334.00
  L 1145.27 351.34
  A 0.39 0.39 0.0 0 0 1145.99 351.34
  L 1153.74 333.99
  Q 1153.97 333.50 1154.51 333.50
  L 1157.75 333.50
  A 0.50 0.50 0.0 0 1 1158.25 334.00
  L 1158.25 357.50
  A 0.25 0.24 -88.9 0 1 1158.00 357.75
  L 1154.50 357.74
  A 0.25 0.24 0.0 0 1 1154.25 357.50
  L 1154.25 342.80
  Q 1154.25 341.72 1153.81 342.71
  Z"
        />
        <path
          fill={white ? '#fff' : "#0071b8"}
          d="
  M 1172.99 337.31
  L 1173.00 343.44
  A 0.32 0.32 0.0 0 0 1173.32 343.76
  L 1180.93 343.75
  A 0.32 0.32 0.0 0 1 1181.25 344.07
  L 1181.25 346.93
  A 0.32 0.32 0.0 0 1 1180.93 347.25
  L 1173.32 347.24
  A 0.32 0.32 0.0 0 0 1173.00 347.56
  L 1172.99 353.93
  A 0.32 0.32 0.0 0 0 1173.31 354.25
  L 1181.92 354.25
  A 0.32 0.32 0.0 0 1 1182.24 354.57
  L 1182.25 357.43
  A 0.32 0.32 0.0 0 1 1181.93 357.75
  L 1169.07 357.75
  A 0.32 0.32 0.0 0 1 1168.75 357.43
  L 1168.75 333.82
  A 0.32 0.32 0.0 0 1 1169.07 333.50
  L 1181.93 333.50
  A 0.32 0.32 0.0 0 1 1182.25 333.82
  L 1182.25 336.68
  A 0.32 0.32 0.0 0 1 1181.93 337.00
  L 1173.31 336.99
  A 0.32 0.32 0.0 0 0 1172.99 337.31
  Z"
        />
      </g>
    </svg>
  );
};

export default Logo;
