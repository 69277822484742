import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const defaults = [
  {
    style: { maxWidth: '55px' },
    src: 'CHAMBERS-OF-COMMERCE.png',
    alt: 'CHAMBERS-OF-COMMERCE',
  },
  {
    style: { maxWidth: '110px' },
    src: 'epa.svg',
    alt: 'EPA',
  },
  {
    style: { maxWidth: '90px' },
    src: 'MS-AuthorizedContractor-Badge-500px.png',
    alt: 'National Kitchen and Bath Association',
  },
  {
    style: { maxWidth: '90px' },
    src: 'HBA Logo of Jefferson.svg',
    alt: 'National Association of Home Builders (NAHB)',
  },
  {  style: { maxWidth: '140px' },src: 'bbb.svg', alt: 'BBB' }, 
]

const windows = [
  { style: { minWidth: '150px' }, src: 'jameshardie.svg', alt: 'James Hardie' },
  { src: 'amerimax.svg', alt: 'Amerimax' },
  { src: 'plygem.svg', alt: 'Plygem' },
  { src: 'alside.svg', alt: 'Alside' },
  { src: 'lp.svg', alt: 'LP', style: { padding: '0 20px' } },
  { src: 'mezzo.svg', alt: 'Mezzo' },
  { src: 'milgard.svg', alt: 'Milgard' },
  { src: 'trex.svg', alt: 'Trex', style: { padding: '0 25px' } },
]

const bathroomremodeling = [
  { src: 'fieldstone.svg', alt: 'Fieldstone' },
  { src: 'kohler.svg', alt: 'Kohler', style: { padding: '0px 8px' } },
  { src: 'toto.svg', alt: 'Toto', style: { padding: '0px 10px' } },
  { src: 'moen.svg', alt: 'Moen', style: { padding: '0px 12px' } },
  { src: 'armstrong.svg', alt: 'Armstrong' },
  { src: 'silestone.svg', alt: 'Silestone', style: { padding: '0 20px' } },
  { src: 'cambria.svg', alt: 'Cambria' },
  { src: 'piedrafina.svg', alt: 'Piedrafina' },
]

const remodeling = [
  { src: 'fieldstone.svg', alt: 'Fieldstone' },
  { src: 'waypoint.svg', alt: 'Waypoint' },
  { src: 'mbci.svg', alt: 'MBCI' },
  { src: 'silestone.svg', alt: 'Silestone', style: { padding: '0 20px' } },
  { src: 'ceasarstone.svg', alt: 'Caesarstone', style: { padding: '12px' } },
  { src: 'cambria.svg', alt: 'Cambria' },
]

const kitchencabinets = [
  { src: 'fieldstone.svg', alt: 'Fieldstone' },
  { src: 'waypoint.svg', alt: 'Waypoint' },
  { src: 'midcontent.svg', alt: 'Midcontent' },
  { src: 'woodmark.svg', alt: 'Woodmark' },
  { src: 'mbci.svg', alt: 'MBCI' },
]

const kitchenremodeling = [
  { src: 'fieldstone.svg', alt: 'Fieldstone' },
  { src: 'waypoint.svg', alt: 'Waypoint' },
  { src: 'mbci.svg', alt: 'MBCI' },
  { src: 'ceasarstone.svg', alt: 'Caesarstone', style: { padding: '12px' } },
  { src: 'silestone.svg', alt: 'Silestone', style: { padding: '0 20px' } },
  { src: 'cambria.svg', alt: 'Cambria' },
]

const siding = [
  { style: { minWidth: '150px' }, src: 'jameshardie.svg', alt: 'James Hardie' },
  { src: 'amerimax.svg', alt: 'Amerimax' },
  { src: 'plygem.svg', alt: 'Plygem' },
  { src: 'alside.svg', alt: 'Alside', style: { padding: '12px' } },
  { src: 'lp.svg', alt: 'LP', style: { padding: '0 20px' } },
  { src: 'mezzo.svg', alt: 'Mezzo' },
  { src: 'milgard.svg', alt: 'Milgard' },
  { src: 'certainteed.svg', alt: 'Certainteed' },
]

const getShowCase = (footerLogoShowcase) => {
  switch (footerLogoShowcase) { 
    default:
      return { items: defaults }
  }
}

const Logos = ({ productLogos }) =>
  productLogos.map(({ style, src, alt }, i) => (
    <div className="p-logo-item p-2" key={i}>
      <img
        src={`/assets/logos/products/${src}`}
        loading="lazy"
        style={{ ...style }}
        alt={alt}
        width="100%"
        height="100%"
        draggable="false"
      />
    </div>
  ))

const ProductLogoShowcase = (footerLogoShowcase) => {
  const { className, items } = getShowCase(
    footerLogoShowcase.footerLogoShowcase
  )
 return null
  return (
 <div className='bg-gradient-to-b from-transparent to-white'>
  <div className=' grid border-b py-4 border-gray-200 grid-cols-3 sm:grid-cols-5 max-w-7xl px-6 mx-auto'>
          <Logos productLogos={items} /></div>
          </div>
  )
}

export default ProductLogoShowcase
