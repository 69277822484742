import React, { useRef } from "react";
import Modal from "react-modal";
import ContactForm from "../components/ContactForm";
import { StaticImage } from "gatsby-plugin-image";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    maxHeight: "90vh",
    width: "95%",
    maxWidth: "1000px",
  },
};

Modal.setAppElement(`body`);

function ContactFormModal({ modalOpen, onModalClose }) {
  const targetRef = useRef(null);

  function closeModal() {
    onModalClose(false);
  }

  return (
    <div ref={targetRef}>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={customStyles}
        contentLabel="Free Estimate"
        className="Modal bg-neutral-50 rounded-xl shadow-lg"
        overlayClassName="Overlay"
      ><div className="flex flex-wrap md:flex-nowrap">
        <div  className=" h-[200px] w-full md:w-[40%] md:h-auto flex-shrink-0 rounded-t-xl">
        <img  src="https://images.ctfassets.net/7h2p3b5vvfl2/4aG7iUPZP9o0zM4LVmVVhg/caf3e84596929daebca446443c637c0d/roofing-contractor-athens-ga.jpg" alt="Roofing Estimate"  className={`h-full w-full  block mb-0  object-cover `} />

        </div>
       
        <div className="px-8 py-6">
          <div className="py-4 flex items-start justify-between ">
            <div>
              <h2 className="font-display font-bold leading-8 text-2xl md:text-3xl md:mb-1">Get a FREE Estimate</h2>
              <p className="text-neutral-600 text-sm md:text-base leading-5 md:leading-6 mb-2 hidden md:inline">
                Contact us today to learn more about our extensive roofing services in Jefferson, GA
                <span className="hidden md:inline">
                  {" "}
                  and relax, knowing that the work is in the hands of capable and experienced professionals
                </span>
                .
              </p>
            </div>
            <button
              onClick={closeModal}
              className="bg-neutral-50 focus:outline-none focus:bg-neutral-100 hover:bg-neutral-100 transition duration-150 ease-in rounded-full p-2"
            >
              <svg
                className="text-neutral-900 w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <ContactForm showPlaceholders={true} hideLabels={true} footerContained={false} grayInputBg={true} />
        </div>
        </div>
      </Modal>
    </div>
  );
}

export default ContactFormModal;
