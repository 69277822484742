import React from 'react'
import { useState } from 'react'
import { Link } from "gatsby"

import { AnimatePresence, motion } from 'framer-motion'
import MenuItemDropdown from './MenuItemDropdown'

export function NavLinks({navigation}) { 

  let [hoveredIndex, setHoveredIndex] = useState(null)
 
  //current pathname
  const path = {pathname: typeof window !== "undefined" ? window.location.pathname : "/"}

 
  return navigation.map((item, index) => (
    item.submenu ? 

        <MenuItemDropdown item={item} label={item.name} hoveredIndex={hoveredIndex} setHoveredIndex={setHoveredIndex} path={path} index={index} />: 
    <Link
      key={item.url}
      to={item.url}
      className="font-display font-medium relative -my-1 -mx-3 rounded-lg px-3 py-6 text-base text-gray-900 hover:text-black  transition-colors delay-150 hover:delay-[0ms] relative"
      onMouseOver={() =>{ setHoveredIndex(index); console.log("ON MOUSE ENTER")}}
      onMouseLeave={() => setHoveredIndex(null)}
    >  
      {/* <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded-lg bg-gray-100"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </AnimatePresence> */} 
      <span className="relative z-10">{item.name}</span> 
      <AnimatePresence>
        {(item.active || (item.url !== "/" && path.pathname.includes(item.url)) || item.url === path.pathname)  && (
          <motion.div
            className="absolute   h-1 w-full  left-0 bottom-1 rounded-t-lg bg-brand-600"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.1, delay: 0.2 },
            }}
          />
        )}
 
      </AnimatePresence>

      {/* <AnimatePresence>
        {hoveredIndex === index && (
          <motion.div
            className="absolute   h-2 w-full  left-0 bottom-0 rounded-lg bg-white"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.1, delay: 0.2 },
            }}
          />
        )}
 
      </AnimatePresence> */}
    </Link>
  ))
}
